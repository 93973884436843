.resources-container
{
	.resources-inner
	{
		padding: 80px 0;

		@media (max-width: $screen-xxl-max)
		{
			padding-top: 70px;
			padding-bottom: 120px;
		}

		@include media-breakpoint-down(lg)
		{
			padding-top: 50px;
			padding-bottom: 70px;
		}

		@include media-breakpoint-down(md)
		{
			padding-bottom: 50px;
		}
	}

	h3
	{
		line-height: 1.4;
	}

	.resources-head
	{
		margin-bottom: 80px;

		.head-inner
		{
			h1
			{
				margin-bottom: 19px;

				&:last-child
				{
					margin-bottom: 0;
				}
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 50px;
		}

		@include media-breakpoint-down(md)
		{
			margin-bottom: 30px;
		}
	}

	.resources-listing
	{
		.listing-head
		{
			text-align: center;
		}

		.resource-block
		{
			background-color: $white-color;
			@include display-inline-flex(center, space-between);
			padding: 30px 37px;
			margin-top: 50px;
			@include default-shadow;
			@include border-radius(8px);
			@include transition;
			cursor: pointer;

			.block-content
			{
				@include display-inline-flex(center, flex-start);
			}

			.download-btn
			{
				font-size: 25px;
			}

			p
			{
				@include transition;
			}

			.image
			{
				margin-right: 20px;
				@include border-radius(999px);
				padding: 21px 23px;
				border: 2px solid $white-color;
				display: inline-block;

				svg
				{
					@include transition;
					width: 38px;
					height: 40px;
				}
			}

			&-warning
			{
				.image
				{
					background-color: $brand-warning;
				}

				.download-btn
				{
					color: $brand-warning;
				}
			}

			&-purple
			{
				.image
				{
					background-color: $brand-purple;
				}

				.download-btn
				{
					color: $brand-purple;
				}
			}


			@include media-breakpoint-down(lg)
			{
				margin-top: 30px;
			}

			@include media-breakpoint-down(md)
			{
				padding: 15px 20px;

				.download-btn
				{
					font-size: 18px;
				}

				.image
				{
					margin-right: 20px;
					@include border-radius(999px);
					padding: 10px 12px;

					svg
					{
						width: 24px;
						height: 25px;
					}
				}
			}

			&:hover, &:active, &:focus, &.active
			{
				p
				{
					color: $white-color;
				}

				.image
				{
					svg
					{
						@include transform(scale(0.9));
					}
				}

				&.resource-block-warning
				{
					background-color: $brand-warning;

					.download-btn
					{
						color: $white-color;
					}
				}

				&.resource-block-purple
				{
					background-color: $brand-purple;
					.download-btn
					{
						color: $white-color;
					}
				}
			}

			&.active
			{
				.image
				{
					svg
					{
						@include animation(zoominout 2s infinite);
					}
				}
			}
		}
	}

	.resources-practices-wrapper
	{
		background-color: $brand-gray-08;

		.resources-inner
		{
			@include media-breakpoint-down(lg)
			{
				padding-bottom: 80px;
			}
		}
	}

	.resources-video-wrapper
	{
		@include position;

		&:before
		{
			content: '';
			@include position(absolute, -1, 0, 0, 0, 50%);
			background-color: $brand-gray-08;
		}

		.resources-video-inner
		{
			@include background-defaults;
			background-size: 100%;
			background-position: top center;

			@include media-breakpoint-down(md)
			{
				background-size: cover;
			}
		}

		.resources-inner
		{
			padding: 185px 0 50px;
			@include media-breakpoint-down(md)
			{
				padding: 100px 0 50px;
			}
		}

		.resources-head
		{
			margin-bottom: 13px;
		}

		.resources-video-container
		{
			h5
			{
				text-align: center;
				margin-bottom: 28px;
			}

			.video-container
			{
				@include border-radius(8px);
				overflow: hidden;
			}
		}

		@include media-breakpoint-down(sm)
		{
			.resources-head
			{
				margin-bottom: 25px;
			}
		}
	}

	.resources-connections-wrapper
	{
		.resources-inner
		{
			padding-bottom: 150px;
		}
		.resources-head
		{
			margin-bottom: 35px;
		}

		.resources-connections-container
		{
			.connection-title
			{
				margin-bottom: 35px;
			}

			.connection-list
			{
				li
				{
					@include position;


					&:before
					{
						content: url('../images/arrow.svg');
						background-color: $brand-primary;
						@include border-radius(999px);
						padding: 8px 11px;
						position: absolute;
						left: 0;
						top: 0;
					}

					padding-left: 63px;
					margin-bottom: 30px;

					p
					{
						color: $brand-primary;
						padding: 7px 0;
					}
				}
			}
		}

		@include media-breakpoint-down(md)
		{
			.resources-inner
			{
				padding-bottom: 100px;
			}

			.resources-head
			{
				margin-bottom: 25px;
			}

			.resources-connections-container
			{
				.connection-title
				{
					margin-bottom: 30px;
				}

				.connection-list
				{
					li
					{
						@include position;


						&:before
						{
							padding: 8px 11px;
						}

						padding-left: 50px;
						margin-bottom: 15px;
					}
				}
			}
		}

		@include media-breakpoint-down(sm)
		{
			.resources-inner
			{
				padding-bottom: 60px;
			}
		}
	}
}