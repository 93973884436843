/*----------  Font Variables  ----------*/

$font-primary				: 'Poppins', sans-serif;
$font-secondary				: 'Playfair Display', serif;
// $sub-font-01				: sans-serif;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontisto				: 'fontisto';
$font-googleicons			: 'Material Icons';
$font-fontawesome			: 'Font Awesome 5 Free';
$font-lineawesome			: 'LineAwesome';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/

$brand-primary				: #426e86;
$brand-secondary			: #424242;
$brand-success				: #28a745;
$brand-info					: #17a2b8;
$brand-warning				: #f9ba32;
$brand-warning-dark			: #ffb00d;
$brand-danger				: #dc3545;
$brand-light				: #fdfdfd;
$brand-dark					: #343a40;
$brand-purple				: #7a4286;

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

// $color-01					: #;
// $color-02					: #;

/*-----  Brands Variables  -----*/

/* Brand Primary */
$brand-primary-01			: #0ba0c6;
// $brand-primary-02			: #;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
$brand-warning-01			: #efac1a;
$brand-warning-02			: #de990b;

/* Brand Success */
// $brand-success-01			: #;
// $brand-success-02			: #;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
$brand-gray-01				: #b0b0b0;
$brand-gray-02				: #a5a5a5;
$brand-gray-03				: #9d9d9d;
$brand-gray-04				: #cfcfcf;
$brand-gray-05				: #d9d9d9;
$brand-gray-06				: #b7b7b7;
$brand-gray-07				: #ececec;
$brand-gray-08				: #f6f6f6;
$brand-gray-09				: #8f8f8f;
$brand-gray-10				: #475053;
$brand-gray-11				: #969696;
$brand-gray-12				: #e7e7e7;
$brand-gray-13				: #c6c6c6;
$brand-gray-14				: #bbbbbb;
$brand-gray-15				: #f7f7f7;
$brand-gray-16				: #a9a9a9;

/* Brand Secondary */
$brand-secondary-01			: #7c7c7c;

/*----------  Font Weight Variables  ----------*/

$weight-hairline 			: 100;
$weight-light	 			: 300;
$weight-regular 			: 400;
$weight-medium				: 500;
$weight-semibold 			: 600;
$weight-bold	 			: 700;
$weight-extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px

/*----------  Grid  ----------*/

$grid-space-default			: 30;
$col-name					: 'cs-col';

/*----------  Containers  ----------*/

$container-default-gutter: 60;
$container-default-widths: (
	sm: 100%,
	md: 100%,
	lg: 100%,
	xl: 1460px
);

/* ----------  Custom Breakpoints  ---------- */

$screen-xxl-max				: 1499px;
