/*----------  Base  ----------*/

p
{
	@include font-md($brand-secondary, $weight-medium, 1.5);
	margin-bottom: 26px;

	&.normal
	{
		font-weight: $weight-regular;
	}

	&.md
	{
		font-size: rem-calc(20px);
	}

	&.lg
	{
		font-size: rem-calc(24px);
	}

	&:last-child
	{
		margin-bottom: 0;
	}

	@include media-breakpoint-down(sm)
	{
		font-size: $font-base;

		&.md
		{
			font-size: $font-md;
		}

		&.lg
		{
			font-size: $font-lg;
		}
	}
}

li, a
{
	@include font-base($brand-secondary, $weight-regular, 1.5);
}

/* ----------  Headings  ---------- */

h1, .heading-h1
{
	@include heading-h1($brand-secondary);

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(36);
	}

	@include media-breakpoint-down(sm)
	{
		font-size: rem-calc(28);
	}
}

.heading-h1-sm
{
	@include heading-h1-sm($brand-secondary);

	@include media-breakpoint-down(lg)
	{
		font-size: $font-xl;
	}

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(34);
	}

	@include media-breakpoint-down(sm)
	{
		font-size: rem-calc(28);
	}
}

h2, .heading-h2
{
	@include heading-h2($brand-secondary);

	@include media-breakpoint-down(lg)
	{
		font-size: rem-calc(35);
	}

	@include media-breakpoint-down(sm)
	{
		font-size: rem-calc(28);
	}
}

h3, .heading-h3
{
	@include heading-h3($brand-secondary);
}

h4, .heading-h4
{
	@include heading-h4($brand-secondary);
}

h5, .heading-h5
{
	@include heading-h5($brand-secondary);

	&-fprimary
	{
		font-family: $font-primary;
		font-weight: $weight-semibold;
	}
	
	@include media-breakpoint-down(lg)
	{
		font-size: rem-calc(25);
	}

	@include media-breakpoint-down(sm)
	{
		font-size: rem-calc(20);
	}
}

h6, .heading-h6
{
	@include heading-h6($brand-secondary);
	&-fprimary
	{
		font-family: $font-primary;
		font-weight: $weight-semibold;
	}

	@include media-breakpoint-down(md)
	{
		font-size: rem-calc(19px);
	}
}
