/* ----------  Posts  ---------- */

.posts-container
{
	.container
	{
		@include media-breakpoint-down(sm)
		{
			padding: 0;
		}
	}

	.posts-inner
	{
		padding: 95px 0;

		@include media-breakpoint-down(lg)
		{
			padding: 80px 0;
		}

		@include media-breakpoint-down(md)
		{
			padding: 60px 0;
		}

		@include media-breakpoint-down(sm)
		{
			padding: 50px 0;
		}
	}

	.posts-header
	{
		margin-bottom: 40px;

		.header-inner
		{
			// @include display-inline-flex(center, space-between);

			@include media-breakpoint-down(sm)
			{
				padding: 0 20px;

				// @include display-flex(column, normal, normal);
			}
		}

		.header-title
		{
			margin-bottom: 50px;
			@include media-breakpoint-down(sm)
			{
				margin-bottom: 20px;
			}
		}
	}

	.posts-listing
	{
		margin-bottom: 80px;

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 60px;
		}

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 40px;
		}
	}

	.posts-loader
	{
		text-align: center;
	}
}
