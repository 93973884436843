/* ----------  Post Card  ---------- */

.post-card
{
	margin-bottom: 70px;

	.card-inner
	{
		overflow: hidden;
		min-height: 380px;
		background: $white-color;

		@include default-shadow;
		@include border-radius(8px);
		@include display-inline-flex(normal);

		@include media-breakpoint-down(md)
		{
			@include flex-direction(column);
		}
	}

	.card-image
	{
		width: 450px;

		@include background-defaults;
		@include position;
		&:before
		{
			content: '';
			background-image: url('../images/curves/news-item.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center left;
			height: 101%;
			width: 82px;
			@include position(absolute, 0, -1px, auto, -1px, -1px);
		}

		@include media-breakpoint-down(lg)
		{
			width: 400px;
		}

		@include media-breakpoint-down(md)
		{
			width: 100%;
			height: 300px;

			&:before, &:after
			{
				display: none;
			}
		}
	}

	.card-details
	{
		@include flex(1);

		.details-inner
		{
			padding: 50px 65px;

			@media (max-width: $screen-xxl-max)
			{
				padding: 40px;
			}

			@include media-breakpoint-down(sm)
			{
				padding: 30px 20px;
			}
		}
	}

	.card-head
	{
		margin-bottom: 25px;
	}

	.card-title
	{
		margin-bottom: 10px;
	}

	.card-meta
	{
		@include display-inline-flex;

		.card-timestamp
		{
			margin-right: 10px;

			&:last-child
			{
				margin: 0;
			}

			@include media-breakpoint-down(sm)
			{
				margin: 0;
				margin-bottom: 10px;
			}
		}

		@include media-breakpoint-down(sm)
		{
			@include align-items(normal);
			@include flex-direction(column);
		}
	}

	.card-timestamp
	{
		p
		{
			font-style: italic;

			@include font-md($brand-gray-02, $weight-regular, 1.4);
		}
	}

	.card-tags
	{
		ul
		{
			li
			{
				margin-right: 10px;

				&:last-child
				{
					margin: 0;
				}
			}
		}
	}

	.card-content
	{
		// @include display-inline-flex(normal);

		.card-text
		{
			// min-height: 145px;
			// padding-right: 60px;
			// border-right: 1px solid $brand-gray-07;

			@include flex(1);

			@media (max-width: $screen-xxl-max)
			{
				padding-right: 40px;
			}

			@include media-breakpoint-down(lg)
			{
				border: 0;
				padding: 0;
				min-height: auto;
				margin-bottom: 30px;
			}
		}

		.card-action
		{
			padding-top: 20px;
			// padding-left: 60px;

			@media (max-width: $screen-xxl-max)
			{
				padding-left: 40px;
			}

			@include media-breakpoint-down(lg)
			{
				padding: 0;
			}
		}

		@include media-breakpoint-down(lg)
		{
			@include flex-direction(column);
		}
	}

	&:last-child
	{
		margin-bottom: 0;
	}

	@include media-breakpoint-down(lg)
	{
		margin-bottom: 50px;
	}

	@include media-breakpoint-down(sm)
	{
		margin-bottom: 30px;
	}
}
