/*----------  Header  ----------*/

header
{
	width: 100%;

	@include position(fixed, 99, 0, 0);
}

/* ----------  Main Navbar  ---------- */

.main-navbar-container
{
	// background: $brand-secondary;

	.navbar-logo
	{
		path, text, tspan
		{
			@include transition;
		}
	}

	.navbar-inner
	{
		padding: 35px 60px;
		@include transition;
		@include position;
		@include display-inline-flex(center, space-between);

		@include media-breakpoint-down(lg)
		{
			padding: 25px 20px;
		}
		
		@include media-breakpoint-down(md)
		{
			@include align-items(normal);
			@include flex-direction(column);
		}
	}

	.navbar-header
	{
		@include position(relative, 11);

		.navbar-logo
		{
			display: block;

			svg
			{
				display: block;
				height: 32px;
			}
		}
	}

	.navbar-actions
	{
		display: none;
		
		@include transform(translateY(-50%));
		@include position(absolute, 99, $top: 50%, $right: 20px);

		.navbar-toggler
		{
			display: block;
			width: 40px;
			height: 24px;
			margin-top: -2px;

			@include position;

			.line
			{
				display: block;
				width: 100%;
				height: 3px;
				background: $white-color;
				
				@include transition;
				@include border-radius(3px);
				@include position(absolute, 5, $left: 0);

				&.line-1
				{
					top: 0;
				}

				&.line-2
				{
					top: 50%;
				}

				&.line-3
				{
					top: 100%;
				}
			}
		}

		@include media-breakpoint-down(md)
		{
			display: block;
		}
	}

	.navbar-contents
	{
		.contents-inner
		{
			@include media-breakpoint-down(md)
			{
				overflow: auto;
				max-height: 100%;
				padding-bottom: 30px;
			}
		}

		ul
		{
			@include display-inline-flex;

			li
			{
				margin-right: 40px;

				a
				{
					display: block;
					letter-spacing: 0.7px;
					text-transform: uppercase;
					
					@include position;
					@include font-base($white-color, $weight-regular, 1.2);

					&:after
					{
						content: '';
						height: 2px;
						width: 100%;
						background: $white-color;

						@include transition;
						@include pointer-events(none);
						@include transform(scaleX(0));
						@include position(absolute, -1, $bottom: -10px, $left: 0);
					}
					
					@include media-breakpoint-down(md)
					{
						padding: 18px 20px;
						color: $brand-secondary;

						&:after
						{
							bottom: 0;
							background: $brand-secondary;

							@include opacity(0.15);
							@include transform(scaleX(1));
						}
					}
				}

				&:last-child
				{
					margin: 0;
				}

				&.active
				{
					a
					{
						font-weight: $weight-bold;
					}
				}

				&:hover:not(.active), &:active:not(.active), &:focus:not(.active)
				{
					a
					{
						&:after
						{
							@include transform(scaleX(1));
						}
					}
				}

				@include media-breakpoint-down(lg)
				{
					margin-right: 25px;
				}

				@include media-breakpoint-down(md)
				{
					margin: 0;
				}
			}

			@include media-breakpoint-down(md)
			{
				@include align-items(normal);
				@include flex-direction(column);
			}
		}

		@include media-breakpoint-down(md)
		{
			width: 280px;
			height: 100%;
			padding-top: 70px;
			background: $brand-light;

			@include opacity(0);
			@include transition;
			@include default-shadow;
			@include pointer-events(none);
			@include transform(translateX(100%));
			@include position(fixed, 88, $top: 0, $right: 0);
		}
	}

	.navbar-backdrop
	{
		width: 100%;
		height: 100%;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include position(fixed, 11, 0, 0);
		@include faded-background($brand-secondary, 0.4);
	}

	&.fixed
	{
		.navbar-inner
		{
			// padding: 20px 60px;
			background-color: $white-color;
			@include default-shadow;
		}

		.navbar-toggler
		{
			.line
			{
				background-color: $brand-secondary;
			}
		}

		.navbar-contents
		{
			ul li
			{
				a
				{
					color: $brand-secondary;

				}

				&.active
				{
					a
					{
						color: $brand-warning;
					}
				}

				&:hover:not(.active), &:active:not(.active), &:focus:not(.active)
				{
					a
					{
						color: $brand-warning;
					}
				}
			}
		}

		.navbar-logo
		{
			.logo-icon
			{
				fill: url('#Fill-1_2_');
			}
			.logo-icon-shadow
			{
				fill: #3F2245;
			}
			.logo-text1
			{
				fill: #7A4286;
			}
			.logo-text2
			{
				fill: #F9BA32;
			}
		}
	}
}

/* ----------  Navbar Open  ---------- */

.app-canvas
{
	&.navbar-open
	{
		overflow: hidden;

		.main-navbar-container
		{
			.navbar-header
			{
				@include filter(blur(3px));
			}

			.navbar-actions
			{
				.navbar-toggler
				{
					.line
					{
						background: $brand-secondary;
						
						&.line-1
						{
							@include transform(translateY(12px) rotate(45deg));
						}

						&.line-2
						{
							@include opacity(0);
						}

						&.line-3
						{
							@include transform(translateY(-12px) rotate(-45deg));
						}
					}
				}
			}

			.navbar-contents
			{
				@include opacity(1);
				@include pointer-events(all);
				@include transform(translateX(0));
			}

			.navbar-backdrop
			{
				@include opacity(1);
				@include pointer-events(all);
			}
		}

		main, footer
		{
			@include filter(blur(3px));
		}
	}
}
