.apropos-container
{
	.mission-wrapper
	{
		@include position;
		.mission
		{
			&-inner
			{
				margin-top: 50px;
				padding: 130px 0 200px;

				.cs-col
				{
					position: static;
				}

				@include media-breakpoint-down(md)
				{
					padding: 0 0 100px;
				}
			}

			&-image-container
			{
				@include position(absolute, 2, 0, auto, 0, 0);
				width: 50%;

				@include media-breakpoint-down(lg)
				{
					top: 50%;
					@include transform(translateY(-70%));
				}

				@include media-breakpoint-down(md)
				{
					width: 100%;
					@include position(relative, 2, auto, auto, auto, auto);
					@include transform(translateY(0));	
					margin-left: 20px;
					margin-bottom: 30px;
				}
			}

			&-head
			{
				margin-bottom: 65px;
			}

			&-listing
			{
				li
				{
					@include position;
					padding-left: 50px;
					margin-bottom: 30px;
					&:before
					{
						content: url('../images/tick.svg');
						padding: 0px 6px 1px;
						@include border-radius(999px);
						border: 3px solid $brand-purple;
						position: absolute;
						top: 7px;
						left: 0;
					}

					p
					{
						color: $brand-gray-09;
					}

					&:last-child
					{
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.about
	{
		&-wrapper
		{
			background-position: cover;
			background-size: 46.97%;
			background-repeat: no-repeat;

			@include media-breakpoint-down(lg)
			{
				background-size: 50%;
			}

			@include media-breakpoint-down(md)
			{
				background-size: contain;
			}
		}

		&-inner
		{
			padding: 150px 0 20px;

			// >.row
			// {
			// 	@include align-items(center);
			// }

			@include media-breakpoint-down(lg)
			{
				padding: 150px 0;
			}

			@include media-breakpoint-down(md)
			{
				padding: 80px 0;
			}

			@include media-breakpoint-down(xs)
			{
				padding: 50px 0 80px;
			}
		}

		&-block-wrapper
		{
			@include position(relative, 10);
			margin-left: -70px;
			
			&:after
			{
				content: '';
				@include position(absolute, 2, 0, 0, 0, 0);
				background-image: url('../images/apropos-dots.png');
				@include background-defaults;
				background-size: contain;
				@include transform(translate(-15%, -10%));
				pointer-events: none
			}

			@media (max-width: 1600px) 
			{
				margin-left: 0;
				&:after
				{
					@include transform(translate(-10%, -10%));		
				}
			}

			@include media-breakpoint-down(lg)
			{
				&:after
				{
					@include transform(translate(0, 0));		
				}
			}
		}

		&-block
		{
			max-width: 590px;
			background-color: $white-color;
			@include default-shadow;

			@include border-radius(8px);
			overflow: hidden;

			.image
			{
				@include position;

				&:before
				{
					content: '';
					background-image: url('../images/curves/home-services.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: bottom center;
					height: 32px;
					@include position(absolute, 0, auto, 0, 0, -1px);
				}
			}

			.quote-content
			{
				padding: 67px 67px 72px 113px;
				h5
				{
					@include position(relative, 1);
					font-size: rem-calc(26px);
					color: $brand-gray-10;

					&:before
					{
						content: '';
						@include position(absolute, -2, -39px, -48px);
						background-image: url('../images/quotes.svg');
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						width: 77px;
						height: 63px;
					}
				}
			}

			@include media-breakpoint-down(md)
			{
				margin: 0 auto;

				.quote-content
				{
					padding: 67px 60px;
				}
			}

			@include media-breakpoint-down(sm)
			{
				.quote-content
				{
					h5
					{
						font-size: rem-calc(22px);
					}
				}
			}

			@include media-breakpoint-down(md)
			{
				.quote-content
				{
					padding: 67px 20px 67px 60px;
				}
			}
		}

		&-content-container
		{
			margin-top: 110px;
			@include media-breakpoint-down(md)
			{
				margin-top: 50px;
			}
		}
	}

	.mindfulness
	{
		&-wrapper
		{
			@include background-defaults;
			background-position: top center;
			padding: 355px 0 75px;
			margin-top: -100px;
			margin-bottom: -50px;


			@include media-breakpoint-down(lg)
			{
				padding: 190px 0 75px;
				background-position: top right;
			}

			@include media-breakpoint-down(md)
			{
				padding: 100px 0;
				margin: 0;
			}

			@include media-breakpoint-down(xs)
			{
				background-position: center center;
			}
		}

		&-head
		{
			margin-bottom: 70px;
		}

		&-content
		{
			p
			{
				text-align: justify;
				font-size: rem-calc(17px);
			}
		}

		&-counter
		{
			&-container
			{
				@include position;
				padding-left: 146px;
				&:before
				{
					content: '';
					@include position(absolute, -1, -19px, 0);
					width: 291px;
					height: 135px;
					background-color: $brand-purple;
					@include border-radius(8px);
				}

				@include media-breakpoint-down(lg)
				{
					padding-left: 80px;
				}

				@include media-breakpoint-down(md)
				{
					margin-top: 50px;
					margin-bottom: 30px;
					padding-left: 80px;

					&:before
					{
						left: auto;
						height: 110px;
					}
				}

				@include media-breakpoint-down(sm)
				{
					&:before
					{
						left: 0;
					}
				}

				@include media-breakpoint-down(xs)
				{
					padding-left: 20px;

					&:before
					{
						width: 250px;
						height: 135px;
					}
				}
			}

			&-block
			{
				margin-top: 50px;
				max-width: 545px;
				background-color: $white-color;
				@include default-shadow;
				@include border-radius(8px);
				@include display-inline-flex(center, center);

				.content
				{
					padding: 37px;
					.counter
					{
						@include font-p(rem-calc(46px), $weight-bold, 1, $brand-secondary);
					}

					p
					{
						margin-left: 10px;
						font-size: $font-lg;
						color: $brand-gray-11;
						line-height: 1;
					}
				}

				@include media-breakpoint-down(md)
				{

				}

				@include media-breakpoint-down(md)
				{
					margin: 20px auto 0;
					.content
					{
						.counter
						{
							font-size: rem-calc(32px);
						}
					}
				}

				@include media-breakpoint-down(xs)
				{
					display: block;
					text-align: center;
					padding: 37px 15px;
					.content
					{
						padding: 0;
						margin-top: 15px;
						.counter
						{
							font-size: rem-calc(28px);
						}

						p
						{
							font-size: $font-base;
						}
					}
				}
			}

		}
	}

	.quote-wrapper
	{
		margin-bottom: 50px;
		.row
		{
			@include align-items(center);
		}

		.quote-container, .contact-container
		{
			text-align: right;
		}

		h5
		{
			margin-bottom: 80px;
			@include position;

			&:before
			{
				content: '';
				@include position(absolute, -2);
				@include transform(translateY(-100%));
				background-image: url('../images/quotes.svg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 77px;
				height: 63px;
			}
		}

		.contact-container
		{
			p
			{
				color: $brand-gray-03;
			}
		}

		.qoute-image-container
		{
			text-align: center;
		}

		@include media-breakpoint-down(md)
		{
			.quote-container, .contact-container
			{
				text-align: center;
			}

			h5
			{
				margin-bottom: 30px;
			}

			.qoute-image-container
			{
				margin-bottom: 70px;
			}
		}

		@include media-breakpoint-down(xs)
		{
			h5
			{
				&:before
				{
					@include transform(translate(-15px, -50%));
				}
			}
			.qoute-image-container
			{
				margin-bottom: 35px;
			}
		}
	}

	.bio
	{
		&-wrapper
		{
			background-color: $brand-secondary;
		}

		&-container
		{
			padding: 80px 0 100px;
			@include position;

			&:before
			{
				content: '';
				background-color: $brand-warning;
				@include position(absolute, -1, 0, auto, 0, auto);
				width: 265px;
				height: 400px;
				@include border-radius(0 0 8px 8px);
			}

			.heading-h1-sm
			{
				margin-bottom: 50px;
			}

			.user
			{
				&-image-container
				{
					margin-top: -100px;
					img
					{
						@include border-radius(9px);
					}
				}

				&-contact
				{
					text-align: center;
					margin: 10px 0;
					.bio-link
					{
						font-size: $font-lg;
						color: $brand-warning;
						border-bottom: 2px solid transparent;

						&:hover, &:active, &:focus
						{
							border-bottom-color: $brand-warning;
						}

						&-white
						{
							color: $white-color;
							&:hover, &:active, &:focus
							{
								border-bottom-color: $white-color;
							}
						}
					}
				}

				&-details-container
				{
					margin-bottom: 42px;
					.heading-h6
					{
						font-weight: $weight-medium;
						margin-bottom: 14px;
					}

					p
					{
						font-weight: $weight-medium;
						text-align: justify;
					}
				}
			}

			@include media-breakpoint-down(lg)
			{
				&:before
				{
					@include position(absolute, -1, 0, auto, -15px, auto);
					height: 350px;
				}
			}

			@include media-breakpoint-down(md)
			{
				.bio-content-container
				{
					text-align: center;
				}
				&:before
				{
					left: 50%;
					height: 350px;
				}
				.user
				{
					&-image-container
					{
						margin-top: 0px;
						text-align: center;
					}

					&-contact
					{
						.bio-link
						{
							font-size: $font-base;
						}
					}

					&-details-container
					{
						text-align: center;
					}
				}
			}

			@include media-breakpoint-down(xs)
			{
				&:before
				{
					display: none;
				}
			}
		}
	}
}

.apropos-modal
{
	.modal-content
	{
		&:before
		{
			content: '';
			@include position(absolute, -1, 0, 0, 0, 0);
			background-image: url('../images/apropos-modal-graphics.png');
			@include background-defaults;
			background-size: auto;
			background-position: left top;
		}
	}

	.bio-container
	{
		@include display-inline-flex(flex-start, space-between);

		.user-image-wrapper
		{
			min-width: 375px;
			margin-right: 95px;
			text-align: center;

			img
			{
				@include border-radius(8px);
			}

			.user-contact
			{
				margin-top: 10px;
			}

			.bio-link
			{
				font-size: $font-lg;
				color: $brand-warning;
				border-bottom: 2px solid transparent;

				&:hover, &:active, &:focus
				{
					border-bottom-color: $brand-warning;
				}

				&-white
				{
					color: $brand-secondary;
					&:hover, &:active, &:focus
					{
						border-bottom-color: $brand-secondary;
					}
				}
			}
		}

		.user-details-wrapper
		{
			.heading-h2
			{
				margin-bottom: 35px;
			}

			.link-linkedin
			{
				font-size: rem-calc(28px);
				color: $brand-primary-01;
				margin-left: 20px;

				&:hover, &:active, &:focus
				{
					color: #0077b5;
				}
			}

			.link-facebook
			{
				font-size: rem-calc(28px);
				color: #3b5998;

				&:hover, &:active, &:focus
				{
					color: darken(#3b5998, 10%);
				}
			}

			p
			{
				font-size: rem-calc(17px);
				color: $brand-gray-09;
				margin-bottom: 5px;
				text-align: justify;
				line-height: 1.8;
			}
		}
	}

	@include media-breakpoint-down(lg)
	{
		.bio-container
		{
			.user-image-wrapper
			{
				min-width: 300px;
				margin-right: 40px;
			}
		}
	}

	@include media-breakpoint-down(md)
	{
		.bio-container
		{
			display: block;

			.user-image-wrapper
			{
				min-width: auto;
				margin-right: 0px;
			}

			.user-details-wrapper
			{
				margin-top: 20px;
				.heading-h2
				{
					text-align: center;
				}
			}
		}
	}
	@include media-breakpoint-down(sm)
	{
		.bio-container .user-details-wrapper
		{
			p
			{
				font-size: $font-base;
			}
		}

		.bio-container .user-image-wrapper .bio-link
		{
			font-size: $font-base;
		}
	}

	@include media-breakpoint-down(xs)
	{
		.bio-container .user-details-wrapper
		{
			.heading-h2
			{
				font-size: rem-calc(24px);
				margin-bottom: 10px;
			}

			.social-links
			{
				display: block;
				margin-top: 10px;
			}

			.link-linkedin
			{
				margin-left: 0;
			}
		}
	}
}
