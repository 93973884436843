/*----------  Grid Spacing  ----------*/

%row, .row
{
	@include row-spacing($grid-space-default);

	@each $space in $grid-spaces
	{
		&.row-#{$space}
		{
			@include row-spacing($space);

			
			@media (max-width: $screen-xxl-max)
			{
				@if $space > $grid-space-default
				{
					@include row-spacing($grid-space-default);
				}
			}
		}
	}
}
