/*----------  Fonts Mixins  ----------*/

@mixin font($font-size, $font-weight, $line-height, $color, $family) 
{
	color: $color;
	font-family: $family;
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
}

@mixin font-icon($font-size, $font-weight, $line-height, $color) 
{
	@include font($font-size, $font-weight, $line-height, $color);
}

@mixin font-p($font-size, $font-weight, $line-height, $color) 
{
	@include font($font-size, $font-weight, $line-height, $color, $font-primary);
}

@mixin font-s($font-size, $font-weight, $line-height, $color) 
{
	@include font($font-size, $font-weight, $line-height, $color, $font-primary);
}

@mixin font-inherit() 
{
	@include font(inherit, inherit, inherit, inherit, inherit);
}
