/*----------  Footer  ----------*/

footer
{
	padding-top: 65px;
	background: $brand-warning;

	@include position;

	p, a, li
	{
		@include font-md($white-color, $weight-regular, 1.2);
	}

	a:not(.btn)
	{
		@include underline-hover;

		&:hover, &:active, &:focus
		{
			color: $white-color;
		}
	}

	@include media-breakpoint-down(md)
	{
		padding-top: 40px;
	}
	
	@include media-breakpoint-down(sm)
	{
		padding-top: 20px;
	}
}

/* ----------  Newsletter  ---------- */

.newsletter-container
{
	&:before
	{
		content: '';
		background-image: url('../images/curves/home-footer.png');
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: top center;
		height: 247px;
		@include position(absolute, 0, -1px, 0, 0, 0);
	}

	.newsletter-inner
	{
		padding-top: 115px;
		padding-bottom: 35px;

		@include media-breakpoint-down(lg)
		{
			padding-top: 80px;
		}

		@include media-breakpoint-down(md)
		{
			padding: 50px 0 25px;
		}
	}

	.newsletter-title
	{
		.newsletter-wrapper
		{
			max-width: calc(100% - 50px);

			@include media-breakpoint-down(lg)
			{
				max-width: 100%;
			}
		}

		h4
		{
			line-height: 1.4;
			color: $white-color;
		}

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 10px;
		}

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 20px;
		}
	}

	.newsletter-form
	{
		margin-left: -60px;

		.form-inner
		{
			padding-right: 20px;

			@include display-inline-flex;

			@include media-breakpoint-down(lg)
			{
				padding: 0;
			}

			@include media-breakpoint-down(xs)
			{
				@include align-items(normal);
				@include flex-direction(column);
			}
		}

		.form-field
		{
			padding: 0 20px;
			background: $white-color;
			
			@include flex(1);
			@include display-inline-flex;
			@include border-radius(5px 0 0 5px);

			.form-icon
			{
				@include font-base($brand-gray-01, $weight-regular, 1);
			}

			.form-control
			{
				border: 0;
				padding: 0;
				height: 50px;
				background: none;
				padding-left: 10px;
				
				@include border-radius(0);
				@include box-shadow(none);
				@include placeholder($brand-gray-01);
				@include font-sm($brand-secondary, $weight-regular, 1.6);

				&:focus
				{
					@include placeholder($brand-secondary);
				}
			}
			
			@include media-breakpoint-down(xs)
			{
				margin-bottom: 15px;

				@include border-radius(5px);
			}
		}

		.form-action
		{
			.btn
			{
				@include border-radius(0 5px 5px 0);

				.btn-icon
				{
					@include transition(transform 0.3s ease-in-out);
				}

				&:hover, &:active, &:focus
				{
					.btn-icon
					{
						@include transform(translateX(5px));
					}
				}

				@include media-breakpoint-down(xs)
				{
					@include border-radius(5px);
				}
			}

			@include media-breakpoint-down(xs)
			{
				@include flex(1);
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin: 0;
		}

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 20px;
		}
	}

	.newsletter-text
	{
		p
		{
			@include font-sm($white-color, $weight-regular, 1.5);
		}
	}

	@include media-breakpoint-down(sm)
	{
		text-align: center;
	}
}

/* ----------  Main Footer  ---------- */

.main-footer-container
{
	.footer-inner
	{
		padding-top: 35px;
		padding-bottom: 30px;
		
		@include media-breakpoint-down(md)
		{
			padding-top: 25px;
			padding-bottom: 10px;
		}
	}

	.footer-block
	{
		margin-bottom: 30px;

		&.block-logo
		{
			a
			{
				svg
				{
					height: 60px;
					max-width: 100%;

					@include media-breakpoint-down(md)
					{
						height: 80px;
					}
				}

				&:after
				{
					display: none;
				}
			}

			@include media-breakpoint-down(md)
			{
				text-align: center;
			}
		}

		&.block-navlist
		{
			margin-bottom: 10px;
			
			.block-wrapper
			{
				@include media-breakpoint-down(sm)
				{
					@include max-width(400px);
				}
			}

			ul
			{
				li
				{
					margin-bottom: 20px;

					a
					{
						@include media-breakpoint-down(xs)
						{
							font-size: $font-base;
						}
					}

					@include media-breakpoint-down(md)
					{
						text-align: center;
					}

					@include media-breakpoint-down(xs)
					{
						margin-bottom: 10px;
					}
				}
			}

			@include media-breakpoint-down(xs)
			{
				margin-bottom: 20px;
			}
		}

		&.block-contact
		{
			a
			{
				&:after
				{
					display: none;
				}
			}

			.list-social
			{
				margin-bottom: 20px;

				li
				{
					margin-right: 15px;

					a
					{
						font-size: rem-calc(24);
					}

					&:last-child
					{
						margin-right: 0;
					}
				}
			}

			.list-info
			{
				li
				{
					margin-bottom: 15px;

					a
					{
						@include display-inline-flex;

						span
						{
							display: block;
						}

						.item-icon
						{
							margin-right: 10px;
						}

						.item-text
						{
							@include underline-hover;
						}

						&:hover, &:active, &:focus
						{
							.item-text
							{
								&:after
								{
									@include opacity(1);
								}
							}
						}
					}

					&:last-child
					{
						margin: 0;
					}
					
					@include media-breakpoint-down(md)
					{
						margin: 0;
						margin-right: 20px;
					}

					@include media-breakpoint-down(xs)
					{
						margin: 0;
						margin-bottom: 15px;
					}
				}

				@include media-breakpoint-down(md)
				{
					@include display-inline-flex(center, center);
				}

				@include media-breakpoint-down(xs)
				{
					@include flex-direction(column);
				}
			}

			@include media-breakpoint-down(md)
			{
				@include display-flex(column, center, center);
			}
		}
	}
}

/* ----------  Rights & Credits  ---------- */

.rights-credits-container
{
	background: $brand-warning-01;

	.rights-credits-inner
	{
		padding-top: 25px;
		padding-bottom: 23px;

		@include display-inline-flex(center, space-between);


		@include media-breakpoint-down(md)
		{
			padding-top: 20px;
			padding-bottom: 15px;
			@include flex-direction(column);
			@include justify-content(center);
		}
	}

	a
	{
		font-weight: $weight-medium;
	}

	p, a
	{
		@include font-sm($white-color, $weight-regular, 1.2);
		@include media-breakpoint-down(md)
		{
			font-size: $font-sm;
			text-align: center;
		}
	}

	.rights-container
	{
		@include media-breakpoint-down(md)
		{
			margin-bottom: 5px;
		}
	}
}
