/* ----------  Extenders  ---------- */

%form_control
{
	height: 120px;
	padding: 0 50px;
	background: $white-color;
	border: 3px solid transparent;

	@include border-radius(8px);
	@include default-shadow(true);
	@include placeholder($brand-gray-06);
	@include font-lg($brand-secondary-01, $weight-medium, 1.6);

	&:focus
	{
		border-color: $brand-warning-dark;

		@include placeholder($brand-secondary-01);
	}

	@include media-breakpoint-down(lg)
	{
		height: 70px;
		padding: 0 35px;
		border-width: 2px;
		font-size: $font-md;
	}

	@include media-breakpoint-down(sm)
	{
		padding: 0 25px;
	}
}

/* ----------  Inputs  ---------- */

.form-control
{
	@include extends('%form_control');
}

textarea.form-control
{
	height: auto;
	min-height: 300px;
	padding-top: 45px;
	padding-bottom: 45px;

	@include media-breakpoint-down(lg)
	{
		min-height: 220px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	@include media-breakpoint-down(sm)
	{
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

select.form-control
{
	cursor: pointer;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	
	&:hover, &:active, &:focus
	{
		border-color: $brand-warning-dark;
	}
}

/* ----------  Labels  ---------- */

label
{
	display: block;
	padding: 0;
	cursor: pointer;
	margin-bottom: 20px;

	@include font-md($brand-secondary, $weight-medium, 1.5);

	@include media-breakpoint-down(lg)
	{
		margin-bottom: 10px;
		font-size: $font-base;
	}
}

/* ----------  Form Group  ---------- */

.form-group
{
	margin-bottom: 60px;

	@include media-breakpoint-down(lg)
	{
		margin-bottom: 40px;
	}

	@include media-breakpoint-down(sm)
	{
		margin-bottom: 30px;
	}
}

/* ----------  Selectric  ---------- */

.selectric-wrapper
{
	&.selectric-open
	{
		z-index: 98;
	}
	.selectric-hide-select
	{
		@include pointer-events(none);

		select
		{
			@include pointer-events(none);
		}
	}

	.selectric
	{
		padding-right: 80px;
		// color: $brand-gray-05;

		@include position;
		@include transition;
		@include extends('%form_control');
		@include display-flex(column, normal, center);

		.label
		{
			margin: 0;
			height: auto;

			@include font-inherit;
		}

		.button
		{
			display: none;
		}

		&:after
		{
			content: "\f110";
			
			@include transform(translateY(-50%));
			@include position(absolute, -1, $top: 50%, $right: 50px);
			@include font-24($brand-gray-05, $weight-semibold, 1, $font-lineawesome);
		}

		@include media-breakpoint-down(lg)
		{
			padding-right: 60px;

			&:after
			{
				right: 35px;
			}
		}
		
		@include media-breakpoint-down(sm)
		{
			padding-right: 50px;

			&:after
			{
				right: 25px;
				font-size: $font-lg;
			}
		}
	}

	.selectric-items
	{
		border: 0;
		overflow: hidden;
		background: $white-color;

		@include default-shadow;
		@include border-radius(5px);

		ul
		{
			li
			{
				padding: 20px 30px;

				@include font-base($brand-secondary-01, $weight-medium, 1.3);

				&.selected, &.highlighted
				{
					background: none;
					color: $brand-warning-dark;
				}

				&:hover:not(.selected):not(.highlighted), &:active:not(.selected):not(.highlighted), &:focus:not(.selected):not(.highlighted)
				{
					background: darken($white-color, 5%);
				}

				@include media-breakpoint-down(lg)
				{
					padding: 15px 25px;
				}
				
				@include media-breakpoint-down(sm)
				{
					padding: 12px 20px;
				}
			}
		}
	}

	&.selectric-above
	{
		.selectric-items
		{
			margin-bottom: 20px;
		}
	}

	&.selectric-below
	{
		.selectric-items
		{
			margin-top: 20px;
		}
	}

	&.selectric-open
	{
		&.selectric-above
		{
			.selectric
			{
				&:after
				{
					content: "\f113";
				}
			}
		}
	}

	&.selectric-open, &:hover, &:active, &:focus
	{
		.selectric
		{
			// color: $brand-secondary-01;
			border-color: $brand-warning-dark;
		}
	}
}

/* ----------  Form Toggle  ---------- */

.toggle
{
	margin: 0;

	@include display-inline-flex;

	.toggle-input
	{
		@include opacity(0);
		@include pointer-events(none);
		@include position(absolute, -1);
	}

	span
	{
		display: block;
	}

	.toggle-icon
	{
		width: 32px;
		height: 32px;
		margin-right: 20px;
		border: 3px solid $brand-secondary;

		@include position;
		@include border-radius(5px);
		@include transition(all 0.15s ease-in-out);

		&:after
		{
			content: "\f17b";

			@include position(absolute, 1, 50%, 50%);
			@include transition(all 0.15s ease-in-out);
			@include transform(translate(-50%, -50%) scale(0));
			@include font-sm($white-color, $weight-semibold, 1, $font-lineawesome);
		}

		@include media-breakpoint-down(lg)
		{
			width: 25px;
			height: 25px;
			margin-right: 15px;
		}
	}

	.toggle-input:checked + .toggle-icon
	{
		background: $brand-secondary;

		&:after
		{
			@include transition-delay(0.1s);
			@include transform(translate(-50%, -50%) scale(1));
		}
	}

	&.toggle-radio
	{
		.toggle-icon
		{
			@include border-radius(50%);
		}
	}
}
