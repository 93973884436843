/* ----------  Contact  ---------- */

.contact-container
{
	.contact-inner
	{
		padding-top: 135px;
		padding-bottom: 100px;

		@include media-breakpoint-down(lg)
		{
			padding-top: 100px;
			padding-bottom: 70px;
		}

		@include media-breakpoint-down(md)
		{
			padding-top: 70px;
			padding-bottom: 50px;
		}
	}

	.contact-header
	{
		margin-bottom: 145px;

		background-position: cover;
		background-size: 46.35%;
		background-repeat: no-repeat;


		.header-inner
		{
			padding: 0 20px;

			@include media-breakpoint-down(lg)
			{
				padding: 0;
			}
		}

		.header-avatar
		{
			padding: 20px 0;
			@include position;

			&:after
			{
				content: '';
				@include position(absolute, 2, -70px, 40px, 50px, 0);
				background-image: url('../images/contact-dots.png');
				@include background-defaults;
				background-size: 100% 100%;
				pointer-events: none;
			}

			.avatar-wrapper
			{
				text-align: center;

				@include media-breakpoint-down(md)
				{
					margin: auto;
					max-width: 100%;
				}
			}

			img
			{
				display: block;
				@include max-width(500px);
				@include transform(rotateY(180deg));

				@include border-radius(8px);
			}

			@include media-breakpoint-down(lg)
			{
				&:after
				{
					@include position(absolute, 2, 0px, 0px, 0px, 0);
				}
			}

			@include media-breakpoint-down(md)
			{
				margin-bottom: 40px;
			}
		}

		.header-info
		{
			margin-top: -40px;

			.info-title
			{
				margin-bottom: 40px;

				@include media-breakpoint-down(md)
				{
					margin-bottom: 30px;
				}
			}

			.info-list
			{
				ul
				{
					li
					{
						margin-bottom: 10px;

						a
						{
							@include display-inline-flex;
							@include font-md($brand-secondary, $weight-regular, 1.5);

							span
							{
								display: block;
							}

							.item-icon
							{
								margin-right: 20px;
								color: $brand-warning-dark;

								@include media-breakpoint-down(xs)
								{
									margin-right: 10px;
								}
							}

							.item-text
							{
								@include underline-hover;
							}

							&:hover, &:active, &:focus
							{
								color: $brand-warning-dark;
							}

							@include media-breakpoint-down(xs)
							{
								font-size: $font-base;
							}
						}

						&:last-child
						{
							margin: 0;
						}
					}
				}
			}

			@include media-breakpoint-down(md)
			{
				margin: 0;
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 100px;
			background-size: 50%;
		}

		@include media-breakpoint-down(md)
		{
			margin-bottom: 70px;
		}

		@include media-breakpoint-down(sm)
		{
			background-size: 100%;
			margin-bottom: 50px;
		}
	}

	.contact-form
	{
		.form-header
		{
			text-align: center;
			margin-bottom: 85px;

			@include media-breakpoint-down(lg)
			{
				margin-bottom: 60px;
			}

			@include media-breakpoint-down(md)
			{
				margin-bottom: 40px;
			}
		}

		.form-contents
		{
			.form-fields
			{
				margin-bottom: 40px;

				@include media-breakpoint-down(md)
				{
					margin-bottom: 20px;
				}
			}

			.form-actions
			{
				.btn
				{
					@include media-breakpoint-down(xs)
					{
						width: 100%;
						
						@include flex(1);
					}
				}
			}
		}
	}
}
