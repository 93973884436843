/* ----------  Underline Hover  ---------- */

@mixin underline-hover
{
	@include position;

	&:after
	{
		content: '';
		height: 2px;
		width: 100%;
		background: $white-color;

		@include opacity(0);
		@include transition;
		@include pointer-events(none);
		@include position(absolute, -1, calc(100% + 4px), 0);
	}

	&:hover, &:active, &:focus, &.active
	{
		&:after
		{
			@include opacity(1);
		}
	}
}

/* ----------  Default Shadow  ---------- */

@mixin default-shadow($force: false)
{
	$shadow: 0 20px 50px 0 rgba($black-color, 0.06);

	@if $force == true
	{
		@include box-shadow($shadow !important);
	}
	@else
	{
		@include box-shadow($shadow);
	}
}


@mixin keyframes($name, $deprecated-prefixes...) {
  	@-moz-keyframes #{$name} {
		@content;
	}
    
    @-webkit-keyframes #{$name} {
		@content;
	}
    
    @-o-keyframes #{$name} {
		@content;
	}
    
    @-ms-keyframes #{$name} {
		@content;
	}
    
    @keyframes #{$name} {
		@content;
	}
}