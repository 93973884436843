/* ----------  Services  ---------- */

.services-container
{
	.services-inner
	{
		padding-top: 95px;
		padding-bottom: 160px;

		@media (max-width: $screen-xxl-max)
		{
			padding-top: 70px;
			padding-bottom: 120px;
		}

		@include media-breakpoint-down(lg)
		{
			padding-top: 50px;
			padding-bottom: 80px;
		}
	}

	h3
	{
		line-height: 1.4;
	}

	.services-head
	{
		margin-bottom: 75px;

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 50px;
		}

		@include media-breakpoint-down(md)
		{
			margin-bottom: 30px;
		}
	}

	.services-listing
	{
		// margin-bottom: 150px;
		.service-card
		{
			margin-bottom: 85px;
			overflow: hidden;

			.card-inner
			{
				overflow: hidden;

				@include position;
				@include border-radius(8px);
				
				@include media-breakpoint-down(lg)
				{
					@include border-radius(0);
				}
			}

			.card-image
			{
				width: 100%;
				height: 100%;

				@include position;
				@include transition;
				@include background-defaults;
				@include position(absolute, 1, 0, 0);

				&:before
				{
					content: '';
					width: 100%;
					height: 100%;

					@include transition;
					@include position(absolute, 2, 0, 0);
					@include faded-background($brand-secondary, 0.4);
				}

				@include media-breakpoint-down(md)
				{
					height: 300px;

					@include reset-position;

					&:before
					{
						display: none;
					}
				}
			}

			.card-title
			{
				margin: 0;
				text-align: center;

				@include transition;
				@include max-width(600px);
				@include position(absolute, 5, 50%, 50%);
				@include transform(translate(-50%, -50%));

				h3
				{
					color: $white-color;

					@include transition;
				}

				&:before
				{
					@include transition;
				}

				@include media-breakpoint-down(md)
				{
					display: none;
				}
			}

			.card-content
			{
				@include opacity(0);
				@include transition;
				@include position(relative, 11);
				@include transform(translateX(50px));
				@include display-flex(column, flex-end, normal);
				
				.content-wrapper
				{
					background: $white-color;
					width: calc(100% - 585px);
					border-radius: 0 7px 7px 0;

					@media (max-width: $screen-xxl-max)
					{
						width: calc(100% - 300px);
					}

					@include media-breakpoint-down(md)
					{
						width: 100%;
					}

					@include position;

					&:before
					{
						content: '';
						background-image: url('../images/curves/services-service-hover.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center left;
						height: 100%;
						width: 163px;
						@include position(absolute, 0, 0, -162px, auto, 0);
					}
				}
				
				.content-inner
				{
					min-height: 700px;
					padding: 55px 85px;
					padding-top: 95px;
					
					@include display-flex(column, normal, normal);

					@media (max-width: $screen-xxl-max)
					{
						padding: 50px 60px;
					}

					@include media-breakpoint-down(lg)
					{
						padding: 40px;
						min-height: 500px;
					}

					@include media-breakpoint-down(md)
					{
						min-height: auto;
						padding: 50px 20px;
					}
				}

				.content-title
				{
					margin-bottom: 50px;

					@include media-breakpoint-down(lg)
					{
						margin-bottom: 30px;
					}
				}

				.content-text
				{
					margin-bottom: 35px;

					@include flex(1);

					p
					{
						margin-bottom: 20px;
						a
						{
							@include font-md($brand-primary, $weight-medium, 1.5);
							border-bottom: 1px solid $brand-primary;
						}

						&:last-child
						{
							margin: 0;
						}
					}

					ul
					{
						@extend .list-unstyled;
						margin-bottom: 20px;
					}

					li
					{
						@include font-md($brand-secondary, $weight-medium, 1.65);
						@include position;
						padding-left: 20px;

						&:before
						{
							content: '\f111';
							font-family: $font-fontawesome;
							font-weight: $weight-bold;
							font-size: 5px;
							@include position(absolute, 2);
							left: 0;
							top: 13px;
						}
					}

					@include media-breakpoint-down(lg)
					{
						margin-bottom: 25px;
					}
				}

				.content-actions
				{
					@include display-inline-flex(center, space-between);

					.atn-link
					{
						@include media-breakpoint-down(sm)
						{
							margin-bottom: 15px;
						}
					}

					@include media-breakpoint-down(sm)
					{
						@include align-items(normal);
						@include flex-direction(column);
						@include justify-content(normal);
					}
				}

				@include media-breakpoint-down(md)
				{
					@include opacity(1);
					@include transform(none);
					@include align-items(normal);
				}
			}

			&:hover, &:active, &:focus
			{
				@include default-shadow;
				.card-image
				{
					&:before
					{
						@include opacity(0);
					}
				}

				.card-title
				{
					left: calc(50% + 10px);

					@include opacity(0);
				}

				.card-content
				{
					@include opacity(1);
					@include transform(translateX(0));
				}

				// .card-title
				// {
				// 	top: 94px;
				// 	left: calc(50% + 157px);

				// 	@include transform(translate(-50%, 0));

				// 	h3
				// 	{
				// 		color: $brand-secondary;
				// 	}

				// 	&:before
				// 	{
				// 		left: 0;
				// 		top: -30px;
				// 		bottom: auto;
				// 		background: $brand-primary;

				// 		@include transform(none);
				// 	}
				// }
			}

			&:last-child
			{
				margin: 0;
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin: 0 -20px;
			// margin-bottom: 80px;
		}

		@include media-breakpoint-down(sm)
		{
			// margin-bottom: 50px;

			.service-card
			{
				margin-bottom: 30px;
			}
		}
	}
}

.services-modal
{
	.service-container
	{
		.service-head-container
		{
			margin-bottom: 56px;

			text-align: center;

			.services-head
			{
				display: inline-block;
			}
		}

		.service-content-container
		{
			padding: 40px 16px 40px 40px;
			border: 1px solid $brand-gray-13;
			@include border-radius(8px);

			.service-content
			{
				max-height: 850px;
				overflow-y: scroll;
				padding-right: 24px;

				ul
				{
					@extend .list-unstyled;
					margin-bottom: 20px;
				}

				p, li
				{
					@include font-md($brand-secondary, $weight-medium, 1.65);

					a
					{
						@include font-md($brand-primary, $weight-medium, 1.5);

						&:hover, &:active, &:focus
						{
							color: $brand-secondary;
						}
					}
				}

				li
				{
					@include position;
					padding-left: 10px;

					&:before
					{
						content: '\f111';
						font-family: $font-fontawesome;
						font-weight: $weight-bold;
						font-size: 5px;
						@include position(absolute, 2);
						left: 0;
						top: 13px;
					}
				}
			}
		}

		.contact-container
		{
			text-align: center;
			margin-top: 60px;
			p
			{
				color: $brand-gray-03;
			}
		}

		@include media-breakpoint-down(md)
		{
			.service-head-container
			{
				margin-bottom: 30px;
			}

			.service-content-container
			{
				padding: 20px 5px 20px 20px;
				.service-content
				{
					max-height: 500px;
					padding-right: 20px;
				}
			}

			.contact-container
			{
				margin-top: 30px;
			}
		}

		@include media-breakpoint-down(xs)
		{
			.service-head-container
			{
				text-align: left;
			}
			.service-content-container
			{
				border: 0;
				padding: 0;
				.service-content
				{
					min-height: 150px;
					p, li
					{
						font-size: $font-base;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(xs)
	{
		.modal-body
		{
			padding: 50px 20px;
		}
	}
}