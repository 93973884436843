/* ----------  Fonts  ---------- */

@mixin font-14($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(14), $weight, $lh, $color, $family);
}

@mixin font-16($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(16), $weight, $lh, $color, $family);
}

@mixin font-18($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(18), $weight, $lh, $color, $family);
}

@mixin font-20($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(20), $weight, $lh, $color, $family);
}

@mixin font-24($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(24), $weight, $lh, $color, $family);
}

@mixin font-30($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(30), $weight, $lh, $color, $family);
}

@mixin font-32($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(32), $weight, $lh, $color, $family);
}

@mixin font-34($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(34), $weight, $lh, $color, $family);
}

@mixin font-40($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(40), $weight, $lh, $color, $family);
}

@mixin font-42($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(42), $weight, $lh, $color, $family);
}

@mixin font-46($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(46), $weight, $lh, $color, $family);
}

@mixin font-50($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(50), $weight, $lh, $color, $family);
}

@mixin font-54($color, $weight, $lh, $family: $font-primary)
{
	@include font(rem-calc(54), $weight, $lh, $color, $family);
}

/* ----------  Headings  ---------- */

@mixin heading-h1($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-54($color, $weight, $lh, $font-secondary);
}

@mixin heading-h1-sm($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-50($color, $weight, $lh, $font-secondary);
}

@mixin heading-h2($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-40($color, $weight, $lh, $font-secondary);
}

@mixin heading-h3($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-42($color, $weight, $lh, $font-secondary);
}

@mixin heading-h4($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-34($color, $weight, $lh, $font-secondary);
}

@mixin heading-h5($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-32($color, $weight, $lh, $font-secondary);
}

@mixin heading-h6($color, $weight: $weight-bold, $lh: 1.2)
{
	@include font-24($color, $weight, $lh, $font-secondary);
}

/* ----------  Base  ---------- */

@mixin font-base($color, $weight, $lh, $family: $font-primary)
{
	@include font-16($color, $weight, $lh, $family);
}

@mixin font-sm($color, $weight, $lh, $family: $font-primary)
{
	@include font-14($color, $weight, $lh, $family);
}

@mixin font-md($color, $weight, $lh, $family: $font-primary)
{
	@include font-18($color, $weight, $lh, $family);
}

@mixin font-lg($color, $weight, $lh, $family: $font-primary)
{
	@include font-20($color, $weight, $lh, $family);
}
