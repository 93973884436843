/* ----------  Global  ---------- */

*
{
	@include selection($brand-warning, $brand-secondary);
}

/*----------  Body  ----------*/

body
{
	@include font-base($brand-secondary, $weight-regular, 1.6);
}

.grecaptcha-badge
{
	@include opacity(0);
	@include pointer-events(none);
}

/* ----------  App Canvas  ---------- */

.app-canvas
{
	min-height: 100vh;

	@include display-flex(column, normal, normal);

	main
	{
		@include flex(1);
	}
}

/* ----------  Buttons  ---------- */

.btn
{
	min-height: 60px;
	padding: 10px 30px;
	border: 3px solid transparent;

	@include border-radius(8px);
	@include font-base($white-color, $weight-regular, 1.1);
	@include display-inline-flex(center, center, initial, true);

	span, i
	{
		display: block;
	}

	.btn-icon
	{
		margin: 0 10px;
		font-size: $font-base;
		font-weight: $weight-semibold;

		&:first-child
		{
			margin-left: 0;
			margin-right: 10px;
		}

		&:last-child
		{
			margin-right: 0;
			margin-left: 10px;
		}

		&:only-child
		{
			margin: 0;
		}
	}

	&.btn-sm
	{
		min-height: 50px;
		border-width: 2px;
		font-size: $font-sm;
	}

	&.btn-block
	{
		@include flex(1);
	}

	&.btn-loader
	{
		@include pointer-events(none);
	}
	
	&.btn-lg
	{
		min-height: 80px;
		border-width: 3px;
		padding-left: 40px;
		font-size: $font-lg;
		padding-right: 40px;

		@include media-breakpoint-down(sm)
		{
			min-height: 60px;
			border-width: 3px;
			padding-left: 30px;
			padding-right: 30px;
			font-size: $font-base;
		}
	}

	&:hover, &:active, &:focus
	{
		@include box-shadow(none !important);
	}
}

/* ----------  Links  ---------- */

.link
{
	@include display-inline-flex;
	@include font-md($brand-primary, $weight-medium, 1.2);

	.link-icon
	{
		display: block;
		font-size: rem-calc(22);
		font-weight: $weight-semibold;

		@include transition;

		&:first-child
		{
			margin-right: 10px;
		}

		&:last-child
		{
			margin-left: 10px;
		}
	}

	&.link-animate
	{
		&:hover, &:active, &:focus
		{
			.link-icon
			{
				&:first-child
				{
					@include transform(translateX(-5px));
				}
	
				&:last-child
				{
					@include transform(translateX(5px));
				}
			}
		}
	}

	@include media-breakpoint-down(sm)
	{
		font-size: $font-base;
	}
}

/* ----------  Banner  ---------- */

.banner-container
{
	.banner-inner
	{
		@include position;
	}

	.banner-image
	{
		width: 100%;
		min-height: 320px;

		@include background-defaults;

		@include position;

		&:before
		{
			content: '';
			background-image: url('../images/curves/hero-curve.png');
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: bottom center;
			height: 176px;
			width: 100%;
			@include position(absolute, -1, auto, 0, 0, -1px);
		}

		@include media-breakpoint-down(sm)
		{
			min-height: 240px;
		}
	}

	&.main-banner
	{
		.banner-image
		{
			min-height: 1100px;
			&:before
			{
				background-image: url('../images/curves/main-hero-curve.png');
				height: 267px;
			}
		}

		@include media-breakpoint-down(lg)
		{
			.banner-image
			{
				min-height: 700px;
			}
		}

		@include media-breakpoint-down(md)
		{
			.banner-image
			{
				min-height: 500px;
			}
		}
	}
}

/* ----------  Bordered Title  ---------- */

.bd-cap
{
	@include position;

	&:before
	{
		content: '';
		height: 3px;
		width: 110px;
		background: $brand-secondary;

		@include position(absolute, -1, -30px, 0);
	}

	&.bd-cap-center
	{
		&:before
		{
			left: 50%;

			@include transform(translateX(-50%));
		}
	}

	&.bd-cap-bottom
	{
		&:before
		{
			top: auto;
			bottom: -30px;
		}
	}

	@include media-breakpoint-down(lg)
	{
		&:before
		{
			top: -15px;
			width: 90px;
		}
	}

	@include media-breakpoint-down(sm)
	{
		&:before
		{
			top: -12px;
			width: 70px;
		}
	}
}

/* ----------  Badge  ---------- */

.badge
{
	padding: 10px 20px;

	@include border-radius(8px);
	@include font-base($white-color, $weight-medium, 1.1);

	&.badge-sm
	{
		padding: 9px 14px;
		padding-bottom: 7px;
		font-size: $font-sm;
	}
}

/* ----------  Spin Loader  ---------- */

.spin-loader
{
	width: 25px;
	height: 25px;
	text-indent: -9999em;
	background: $brand-purple;
	
	background: -moz-linear-gradient(left, $brand-purple 10%, rgba($brand-purple, 0) 42%);
	background: -webkit-linear-gradient(left, $brand-purple 10%, rgba($brand-purple, 0) 42%);
	background: -o-linear-gradient(left, $brand-purple 10%, rgba($brand-purple, 0) 42%);
	background: -ms-linear-gradient(left, $brand-purple 10%, rgba($brand-purple, 0) 42%);
	background: linear-gradient(to right, $brand-purple 10%, rgba($brand-purple, 0) 42%);

	-webkit-animation: spin 1.4s infinite linear;
	animation: spin 1.4s infinite linear;

	@include position;
	@include border-radius(50%);
	@include transform(translateZ(0));

	&:before
	{
		content: '';
		width: 50%;
		height: 50%;
		background: $white-color;
		
		@include border-radius(100% 0 0 0);
		@include position(absolute, 1, 0, 0);
	}

	&:after
	{
		content: '';
		width: 75%;
		height: 75%;
		margin: auto;
		background: $white-color;

		@include border-radius(50%);
		@include position(absolute, 1, 0, 0, 0, 0);
	}
}

@-webkit-keyframes spin
{
	0%
	{
		@include transform(rotate(0deg));
	}
	100%
	{
		@include transform(rotate(360deg));
	}
}

@keyframes spin
{
	0%
	{
		@include transform(rotate(0deg));
	}
	
	100%
	{
		@include transform(rotate(360deg));
	}
}

.modal
{
	.modal-xl
	{
		@media (min-width: 1499px)
		{
			max-width: 1440px;
		}

		@media (max-width: 1499px)
		{
			max-width: 100%;
			margin: 20px;
		}
	}

	.modal-content
	{
		border: 0px;
		@include box-shadow(0 18px 40px 0px rgba($black-color, 0.24));
		@include position;
		@include border-radius(8px);

		.closeBtn
		{
			@include position(absolute, 2, 30px, auto, 45px);
			font-size: 40px;
			color: $brand-gray-12;

			&:hover, &:active, &:focus
			{
				color: $brand-secondary;
			}
		}
	}

	.modal-body
	{
		padding: 110px 150px;
	}

	@media (max-width: 1499px)
	{
		.modal-body
		{
			padding: 80px 100px;
		}
	}

	@include media-breakpoint-down(md)
	{
		.modal-body
		{
			padding: 60px 70px;
		}
	}

	@include media-breakpoint-down(sm)
	{
		.modal-content .closeBtn
		{
			font-size: 20px;
			top: 20px;
			right: 20px;
		}

		.modal-body
		{
			padding: 50px;
		}
	}
}

.modal-backdrop
{
	background-color: $brand-secondary;
}

@include keyframes (zoominout) {
    0% {
        @include transform(scale(1,1));
    }
    50% {
        @include transform(scale(0.8,0.8));
    }
    100% {
        @include transform(scale(1,1));
    }
}