/*----------  Index View  ----------*/
.homepage
{
	.main-banner
	{
		.banner-image
		{
			@include display-flex(column, center, center);
		}

		.banner-content
		{
			max-width: 770px;

			.heading-h1
			{
				margin-bottom: 66px;
				@include position;

				&:before
				{
					content: '';
					@include position(absolute, -1, 35px, -34px);
					height: 2px;
					width: 97px;
					background-color: $white-color;
					@include transform(translateX(-100%));
				}
			}
		}

		@include media-breakpoint-down(md)
		{
			.banner-content
			{
				.heading-h1
				{
					margin-bottom: 35px;
				}
			}
		}
	}

	.services-wrapper
	{
		padding: 170px 0;

		.service-block
		{
			background-color: $white-color;
			@include default-shadow;
			@include border-radius(8px);
			@include transition;
			overflow: hidden;
			cursor: pointer;

			.image-container
			{
				@include position;

				&:before
				{
					content: '';
					background-image: url('../images/curves/home-services.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: bottom center;
					height: 32px;
					@include position(absolute, 0, auto, 0, 0, -1px);
				}

				&:after
				{
					content: '';
					background-image: url('../images/curves/home-services-hover.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: bottom center;
					height: 32px;
					@include opacity(0);
					@include transition;
					@include position(absolute, 1, auto, 0, 0, -1px);
				}
			}

			.content-container
			{
				padding: 35px 45px 42px 45px;
				text-align: center;
				.content
				{
					padding-bottom: 40px;
					margin-bottom: 30px;
					@include position;

					&:after
					{
						content: '';
						height: 1px;
						width: 200px;
						background-color: $brand-gray-15;
						@include absolute-center(x);
						top: auto;
						bottom: 0;
						@include transition;
					}
				}

				h6
				{
					margin-bottom: 15px;
					color: $brand-warning;
					@include transition;
				}

				p
				{
					font-size: $font-base;
					font-weight: $weight-medium;
					color: $brand-gray-09;
					@include transition;

					&.meta
					{
						font-style: italic;
						color: $brand-gray-14;
						font-weight: $weight-regular;
					}
				}
			}

			&:hover, &:active, &:focus
			{
				background-color: $brand-warning;
				@include box-shadow(0 20px 50px 0px rgba($brand-warning-dark, 0.25));

				.image-container
				{
					&:after
					{
						@include opacity(1);
					}
				}

				.content-container
				{
					.content
					{
						&:after
						{
							background-color: $brand-warning-02;
						}
					}

					h6, p, p.meta
					{
						color: $white-color;
					}
				}
			}
		}

		@include media-breakpoint-down(md)
		{
			padding: 100px 0 0;
			.service-block
			{
				margin-bottom: 50px;
				.content-container
				{
					padding: 25px;
					.content
					{
						padding-bottom: 25px;
						margin-bottom: 15px;
					}
				}
			}
		}

		@include media-breakpoint-down(sm)
		{
			padding: 50px 0 0;
			.service-block
			{
				margin-bottom: 30px;
				.content-container
				{
					padding: 20px;
					.content
					{
						padding-bottom: 20px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.features
	{
		&-wrapper
		{
			padding: 74px 0 140px;
		}

		&-head-wrapper
		{
			>.row
			{
				@include align-items(center);
			}

			margin-bottom: 135px;
		}

		&-head
		{
			max-width: 460px;
		}

		&-block-container
		{
			margin-bottom: 40px;
			.feature-block
			{
				padding: 30px 40px;
				background-color: $white-color;
				@include default-shadow;
				@include border-radius(8px);
				margin-bottom: 40px;

				&-inner
				{
					@include display-inline-flex(center, flex-start);
					.icon
					{
						margin-right: 16px;
					}

					p
					{
						font-weight: $weight-regular;
					}
				}
			}
		}

		&-foot
		{
			text-align: center;
		}

		@include media-breakpoint-down(sm)
		{
			&-wrapper
			{
				padding: 50px 0;
			}

			&-head-wrapper
			{
				margin-bottom: 30px;
			}

			&-head
			{
				max-width: 100%;
				margin-bottom: 30px;
			}

			&-block-container
			{
				margin-bottom: 20px;

				.feature-block
				{
					margin-bottom: 25px;
					padding: 15px 20px;
				}
			}
		}
	}

	.testimonial
	{
		&-wrapper
		{
			padding: 273px 0 110px;
			min-height: 970px;
			@include background-defaults;

			.testimonials
			{
				@include position;
				.testimonial
				{
					&-block-inner
					{
						background-color: $white-color;
						@include border-radius(8px);
						@include default-shadow;
						padding: 86px 51px 74px 79px;

						p
						{
							@include position;
							color: $brand-gray-16;

							&:before
							{
								content: '';
								@include position(absolute, -2);
								background-image: url('../images/quotes.svg');
								background-repeat: no-repeat;
								background-size: cover;
								background-position: center;
								width: 77px;
								height: 63px;
								left: -38px;
								top: -45px;
							}
						}
					}

					&-block
					{
						padding-bottom: 77px;
						&.single
						{
							padding-top: 260px;
						}
					}
				}

				.slick-list
				{
					margin: 0 -50px;
					padding: 0 50px;

					@media (max-width: 1499px)
					{
						margin: 0 -20px;;
						padding: 0 20px;;
					}
				}

				.slick-navbtn
				{
					position: absolute;
					bottom: 98px;
					cursor: pointer;
					z-index: 11;

					svg
					{
						path
						{
							fill: #2c4e60;
							@include transition;
						}
					}

					&-prev
					{
						@include transform(translateX(-100%) rotate(180deg));
						left: calc(50% - 135px);
						bottom: 97px;
					}

					&-next
					{
						@include transform(translateX(-100%));
						left: calc(50% - 50px);
					}

					&:hover, &:active, &:focus
					{
						svg
						{
							path
							{
								fill: $white-color;
							}
						}
					}
				}
			}
		}

		&-head
		{
			max-width: 375px;
			@include position(absolute, 3);
			@include transform(translateY(80px));
		}

		@include media-breakpoint-down(md)
		{
			&-wrapper
			{
				padding: 150px 0 50px;
				min-height: auto;

				.testimonials
				{
					.testimonial
					{
						&-block-inner
						{
							padding: 56px 30px 45px 50px;

							p
							{
								&:before
								{
									background-size: 100%;
									width: 50px;
									height: 40px;
									left: -18px;
									top: -10px;
								}
							}
						}

						&-block
						{
							padding-bottom: 20px;
							&.single
							{
								padding-top: 0px;
							}
						}
					}

					.slick-list
					{
						padding-bottom: 40px;
					}

					.slick-navbtn
					{
						bottom: 0;

						&-prev
						{
							@include transform(translateX(-100%) rotate(180deg));
							left: calc(50% - 25px);
							bottom: 0;
						}

						&-next
						{
							@include transform(translateX(0%));
							left: calc(50% + 25px);
						}
					}
				}
			}

			&-head
			{
				max-width: 100%;
				margin-bottom: 30px;
				@include position(relative, 3);
				@include transform(translateY(0px));
			}
		}
	}

	.contact
	{
		&-wrapper
		{
			padding-top: 140px;
		}

		&-container
		{
			>.row
			{
				@include align-items(center);
			}

			.contact-head
			{
				max-width: 570px;

				h4
				{
					margin-bottom: 50px;
				}
			}
		}

		@include media-breakpoint-down(sm)
		{
			&-wrapper
			{
				padding-top: 70px;
			}

			&-container
			{
				.image-container
				{
					text-align: center;
				}

				.contact-head
				{
					max-width: 100%;
					margin-bottom: 30px;
					h4
					{
						margin-bottom: 30px;
					}

					.action-container
					{
						text-align: center;
					}
				}
			}
		}

		@include media-breakpoint-down(sm)
		{
			&-container
			{
				.contact-head
				{
					h4
					{
						font-size: rem-calc(24px);
					}
				}
			}
		}
	}
}