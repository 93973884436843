/*----------  Selection  ----------*/

@mixin global-selection($color: $white-color, $background: $brand-primary)
{
	::selection 
	{
		color: $color;
		background: $background;
	}

	::-moz-selection 
	{
		color: $color;
		background: $background;
	}
}

@mixin selection($color: $white-color, $background: $brand-primary)
{
	&::selection 
	{
		color: $color;
		background: $background;
	}

	&::-moz-selection 
	{
		color: $color;
		background: $background;
	}
}