/* ----------  Single Post  ---------- */

.post-details-container
{
	.container
	{
		@include media-breakpoint-down(sm)
		{
			padding: 0;
		}
	}

	.post-details-inner
	{
		padding-top: 100px;
		padding-bottom: 155px;

		@include media-breakpoint-down(lg)
		{
			padding-top: 70px;
			padding-bottom: 110px;
		}
		
		@include media-breakpoint-down(md)
		{
			padding-top: 50px;
			padding-bottom: 80px;
		}

		@include media-breakpoint-down(sm)
		{
			padding-bottom: 0;
		}
	}

	p
	{
		margin-bottom: 35px;

		&:last-child
		{
			margin: 0;
		}

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 25px;
		}
	}

	.post-header
	{
		margin-bottom: 40px;

		
		.header-inner
		{
			@include display-inline-flex(flex-end, space-between);

			@include media-breakpoint-down(sm)
			{
				padding: 0 20px;

				@include align-items(normal);
				@include flex-direction(column);
			}
		}

		.post-action
		{
			@include order(2);

			@include media-breakpoint-down(sm)
			{
				margin-bottom: 40px;

				@include order(unset);
			}
		}

		@include media-breakpoint-down(lg)
		{
			margin-bottom: 30px;
		}
	}

	.post-content
	{
		.content-inner
		{
			overflow: hidden;
			background: $white-color;

			@include default-shadow;
			@include border-radius(7px);
			
			@include media-breakpoint-down(sm)
			{
				@include border-radius(0);
				@include box-shadow(none);
			}
		}

		.content-banner
		{
			margin-bottom: 15px;

			.banner-image
			{
				@include position;

				&:before
				{
					content: '';
					background-image: url('../images/curves/news-single.png');
					background-size: 100%;
					background-repeat: no-repeat;
					background-position: bottom center;
					height: 115px;
					@include position(absolute, 0, auto, 0, 0, -1px);
				}
			}
		}

		.content-details
		{
			.details-inner
			{
				padding: 100px;
				padding-top: 0;

				@include media-breakpoint-down(lg)
				{
					padding: 50px;
					padding-top: 0;
				}

				@include media-breakpoint-down(md)
				{
					padding: 30px;
					padding-top: 0;
				}

				@include media-breakpoint-down(sm)
				{
					padding: 0 20px;
					padding-bottom: 50px;
				}
			}
		}

		.content-header
		{
			margin-bottom: 40px;
			
			@include media-breakpoint-down(sm)
			{
				margin-bottom: 25px;
			}
		}
		
		.content-tags
		{
			margin-bottom: 10px;
		}
		
		.content-title-share
		{
			@include display-inline-flex(flex-start, space-between);

			@include media-breakpoint-down(lg)
			{
				@include align-items(normal);
				@include flex-direction(column);
			}
		}

		.content-title
		{
			h2
			{
				margin-bottom: 10px;

				&:last-child
				{
					margin: 0;
				}
			}

			p
			{
				font-style: italic;

				@include font-md($brand-gray-02, $weight-regular, 1.3);
			}

			@include media-breakpoint-down(lg)
			{
				margin-bottom: 30px;
			}
			
			@include media-breakpoint-down(sm)
			{
				margin-bottom: 20px;
			}
		}

		.content-share-footer
		{
			@include display-flex(column, flex-end, center);
			margin-top: 30px;
			@include media-breakpoint-down(sm)
			{
				@include align-items(center);
				text-align: center;
			}
		}

		.content-share
		{
			p
			{
				margin-bottom: 10px;

				@include font-md($brand-gray-03, $weight-regular, 1.2);
			}
			
			ul
			{
				@include justify-content(space-between);

				li
				{
					margin-right: 10px;

					a
					{
						display: block;

						@include font-24($brand-gray-04, $weight-regular, 1, inherit);

						&:hover, &:active, &:focus
						{
							color: $brand-warning-dark;
						}
					}

					&:last-child
					{
						margin: 0;
					}
				}

				@include media-breakpoint-down(lg)
				{
					@include justify-content(normal);
				}

				@include media-breakpoint-down(sm)
				{
					@include justify-content(center);
				}
			}
		}

		.content-text
		{
			margin-bottom: 60px;
			@include font-md($brand-secondary, $weight-medium, 1.5);

			@include media-breakpoint-down(md)
			{
				margin-bottom: 40px;
			}
		}

		.content-info-cta
		{
			text-align: center;

			h5
			{
				@include font-lg($brand-gray-03, $weight-medium, 1.4);

				@include media-breakpoint-down(sm)
				{
					font-size: $font-md;
				}
			}

			.info-title
			{
				margin-bottom: 20px;
			}
		}
	}
}
