/*----------  Global Contextuals  ----------*/

@each $class, $primary, $secondary in $global-contextuals
{
	.text-#{$class}
	{
		@include text-color($primary);
	}

	.link.link-#{$class}
	{
		@include text-color($primary, true);
	}

	.bg-#{$class}
	{
		@include bg-color($primary);
	}

	.btn.btn-#{$class}
	{
		@include button-theme($secondary, $primary, $primary);

		&:hover, &:active, &:focus
		{
			@include button-theme($primary, transparent, $primary);
		}
	}

	.btn.btn-#{$class}-outline
	{
		@include button-theme($primary, transparent, $primary);
		
		&:hover, &:active, &:focus
		{
			@include button-theme($secondary, $primary, $primary);
		}
	}

	.bd-cap.bd-cap-#{$class}
	{
		&:before
		{
			background: $primary;
		}
	}

	.badge.badge-#{$class}
	{
		@include bg-color($primary);

		&:hover, &:active, &:focus
		{
			@include bg-color(darken($primary, 5%));
		}
	}

	.toggle
	{
		&.toggle-#{$class}
		{
			color: $primary;

			.toggle-icon
			{
				border-color: $primary;
			}

			.toggle-input:checked + .toggle-icon
			{
				background: $primary;
			}
		}
	}
}
